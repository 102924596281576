@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import "./variables.scss";
@font-face {
    font-family: Assistant-Bold;
    src: url("../fonts/Assistant-Bold.ttf");
}

@font-face {
    font-family: Assistant-ExtraBold;
    src: url("../fonts/Assistant-ExtraBold.ttf");
}

@font-face {
    font-family: Assistant-ExtraLight;
    src: url("../fonts/Assistant-ExtraLight.ttf");
}

@font-face {
    font-family: Assistant-Light;
    src: url("../fonts/Assistant-Light.ttf");
}

@font-face {
    font-family: Assistant-Medium;
    src: url("../fonts/Assistant-Medium.ttf");
}

@font-face {
    font-family: Assistant-Regular;
    src: url("../fonts/Assistant-Regular.ttf");
}

@font-face {
    font-family: Assistant-SemiBold;
    src: url("../fonts/Assistant-SemiBold.ttf");
}

@font-face {
    font-family: Rubik-Black;
    src: url("../fonts/Rubik-Black.ttf");
}

* {
    font-family: "Assistant", sans-serif;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
    width: 16px;
}
::-webkit-input-placeholder,
::-webkit-scrollbar-thumb{
    background-clip: padding-box;
    border-radius: 16px;
    border: 4px solid transparent;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    -webkit-transition: background-color 0.1s ease;
    transition: background-color 0.1s ease;
}