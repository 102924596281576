@import "./variables.scss";
body {
    padding: 0 !important;
    margin: 0;
    font-family: Assistant-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.app {
    height: 100%;
    min-height: 100vh;
}

.MuiFormLabel-root {
    font-family: Assistant-Regular;
}
