html {
  scroll-behavior: smooth;
}

.navbarHome ul {
  list-style-type: none;
}
.navbarHome a {
  text-decoration: none;
}
.navbarHome {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: #31758e;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.158);
  z-index: 2;
  height: 4ex;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  padding: 2.5rem;
  font-size: 1.3rem;
}
.links-container {
  width: 100%;
  padding: 0 15vw;
}
.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-link {
  color: white;
  font-size: 1.7vw;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  display: block;
}

.logo-main {
  height: 50%;
  width: 50%;
}
.hero {
  font-size: 40px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color: #1cbef3;
  padding-top: 17vh;
}
.logo-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.logo--text {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: clamp(20px, 6vw, 200px);
}
.logo--sub-text {
  font-size: clamp(8px, 5vw, 38px);
}
.logo {
  width: clamp(7px, 20vw, 150px);
  height: clamp(7px, 20vw, 150px);
  margin-left: clamp(2px, 20vw, 32px);
}
.loginAndSignUp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.loginAndSignUp-content {
  margin: 2rem;
}
.loginAndSignUp-content > p {
  margin: 0;
  font-size: clamp(16px, 5vw, 20px);
}
.signInLogo {
  height: clamp(35px, 5vw, 80px);
  width: clamp(35px, 5vw, 80px);
}

.teacher {
  margin: 0px;
  font-weight: 700;
}
.sub {
  margin: 0px;
}
.bold {
  font-weight: 700;
}
.gray {
  background-color: #ededed;
  font-size: clamp(16px, 5vw, 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  justify-content: center;
  font-weight: 300;
  color: #31758e;
  text-align: center;
}
.row {
  width: 100%;
  display: flex;
  height: 50vw;
}
.col {
  width: 50%;
  height: 100%;
}

.col__blue {
  font-size: clamp(16px, 2.5vw, 30px);
  background-color: #35788b;
  padding: 0 2em;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.medium-font {
  font-size: clamp(20px, 4vw, 36px);
}

.col--title {
  font-size: clamp(16px, 5vw, 40px);
  font-weight: 700;
  margin: 0;
}

.section-title {
  font-size: clamp(10px, 6vw, 42px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.howItWork {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.how-it-work--row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.how-it-work--col {
  /*width: 28%;*/
  position: relative;
  background-color: #31758e;
}
.how-it-work--content {
  padding: 2rem;
  font-size: clamp(20px, 3vw, 24px);
}

.image-container {
  position: relative;
  display: inline-block;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.how-it-work-img {
  width: 30vw;
  height: 700px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #31758ed7;
  pointer-events: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  text-align: center;
  height: 700px;
  width: 30vw;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 100%;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.how-it-work-videos {
  width: 100%;
  padding: 0 8rem 10rem 8rem;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.how-it-work-video {
  height: 20rem;
  width: 40%;
}

.how-it-work-video p {
  font-size: 1.2rem;
  font-weight: 700;
  color: #31758e;
}

.contactForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
  width: 100vw;
}

.contactForm input {
  direction: rtl;
  background-color: white;
  height: clamp(3rem, 5vw, 30px);
  width: clamp(40px, 590px, 50vw);
  border: none;
  padding: 16px;
  margin-bottom: clamp(10px, 3vw, 14px);
  border-radius: 0.2rem;
  font-size: clamp(16px, 3vw, 26px);
}

.send {
  background-color: #1cbef3;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: clamp(20px, 4vw, 26px);
  height: clamp(50px, 3vw, 40px);
  width: clamp(150px, 4vw, 500px);
  border: none;
  padding: 1rem;
  border-radius: 0.2rem;
  cursor: pointer;
}

.contactForm ::-webkit-input-placeholder {
  color: #191919;
  background: transparent;
  font-size: clamp(16px, 3vw, 22px);
}

.contactForm textarea:focus,
input:focus {
  outline: none;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #31758e;
  width: 100vw;
}

.contact--text {
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 1rem;
}

.contact--text--title {
  font-weight: 600;
  font-size: clamp(20px, 3.5vw, 32px);
  margin: 0;
  width: 100%;
  margin-bottom: 1rem;
}

.contact--text--body {
  margin: 0;
  font-size: clamp(12px, 3.5vw, 20px);
}

.note {
  background-color: #31758e94;
  width: 100%;
  color: white;
  font-size: 1.5rem;
  padding: 2rem;
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note img {
  margin-left: 0.5rem;
}

.closer {
  background-color: white;
  font-size: 1.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1cbef3;
  padding: 2.5rem;
  font-size: 2rem;
  text-align: center;
}

.menu-button {
  display: none;
}

@media (max-width: 600px) {
  .links-container {
    padding: 0;
  }
  .menu-button {
    display: block;
  }

  .nav-links {
    display: none;
    /* Additional styling for the dropdown appearance */
  }
  .row {
    width: 100%;
    display: flex;
    height: 50rem;
  }
  .row1 {
    flex-direction: column;
  }

  .row2 {
    flex-direction: column-reverse;
  }

  .col {
    width: 100%;
    height: 25rem;
  }

  .how-it-work--row {
    display: flex;
    flex-direction: column;
  }

  .how-it-work--col {
    width: 100%;
  }

  .overlay {
    width: 100vw;
    height: 500px;
  }

  .how-it-work-img {
    width: 100vw;
    object-fit: cover;
    height: 500px;
  }

  .how-it-work--col {
    margin-bottom: 40px;
  }

  .how-it-work-videos {
    display: flex;
    flex-direction: column;
  }

  .how-it-work-video {
    width: 70vw;
    margin-bottom: 100px;
  }
}
